import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RowFixed, RowBetween } from '../Row'
import { useMedia } from 'react-use'
import { useGlobalData, useEthPrice, useGlobalChartData } from '../../contexts/GlobalData'
import { formattedNum, localNumber } from '../../utils'

import PanaPrice from '../PanaPrice'
import { TYPE } from '../../Theme'
import { useNetworkSelection } from '../../contexts/LocalStorage'
import { SupportedNetwork } from '../../constants/networks'

const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
`

const Medium = styled.span`
  font-weight: 500;
`

export default function GlobalStats() {
  const below1295 = useMedia('(max-width: 1295px)')
  const below1180 = useMedia('(max-width: 1180px)')
  const below1024 = useMedia('(max-width: 1024px)')
  const below500 = useMedia('(max-width: 500px)')
  const below816 = useMedia('(max-width: 816px)')

  const [network] = useNetworkSelection()
  const [networkName, setNetworkName] = useState()

  const [showPriceCard, setShowPriceCard] = useState(false)

  useEffect(() => {
    switch (network) {
      case SupportedNetwork.ETHEREUM:
        setNetworkName('ETH');
        break;
      case SupportedNetwork.ARBITRUM:
        setNetworkName('ARB');
        break;
      case SupportedNetwork.OPTIMISM:
        setNetworkName('OP');
        break;
      case SupportedNetwork.POLYGON:
        setNetworkName('MATIC');
        break;
      default:
        setNetworkName('MATIC');
        break;
    }
  },[network])

  const { oneDayVolumeUSD, oneDayTxns, pairCount } = useGlobalData()
  const [dailyData, weeklyData] = useGlobalChartData()
  const [ethPrice] = useEthPrice()
  const formattedEthPrice = ethPrice ? formattedNum(ethPrice, true) : '-'
  const oneDayFees = oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD * 0.003, true) : ''

  return (
    <Header>
      <RowBetween style={{ padding: below816 ? '0.5rem' : '.5rem' }}>
        <RowFixed>
          {!below500 && (
            <>
              <TYPE.main
                mr={'1rem'}
                onMouseEnter={() => {
                  setShowPriceCard(true)
                }}
                onMouseLeave={() => {
                  setShowPriceCard(false)
                }}
                style={{ position: 'relative' }}
              >
                {networkName} Price: <Medium>{formattedEthPrice}</Medium>
                {showPriceCard && <PanaPrice />}
              </TYPE.main>

              <TYPE.main mr={'1rem'}>
                Transactions (24H): <Medium>{localNumber(oneDayTxns)}</Medium>
              </TYPE.main>
              <TYPE.main mr={'1rem'}>
                Pairs: <Medium>{localNumber(pairCount)}</Medium>
              </TYPE.main>
              <TYPE.main mr={'1rem'}>
                Fees (24H): <Medium>{oneDayFees}</Medium>&nbsp;
              </TYPE.main>
            </>
          )}
          {
            below500 && (
              <div className='d-flex justify-content-between' style={{gap: "1rem"}}>
                <div className='d-flex flex-column'>
                  <TYPE.main mr={'1rem'} onMouseEnter={() => { setShowPriceCard(true) }} onMouseLeave={() => { setShowPriceCard(false) }} style={{ position: 'relative' }}>
                    {networkName} Price: <Medium>{formattedEthPrice}</Medium>
                    {showPriceCard && <PanaPrice />}
                  </TYPE.main>

                  <TYPE.main className="mt-1">
                    Transactions (24H): <Medium>{localNumber(oneDayTxns)}</Medium>
                  </TYPE.main>
                </div>
                <div className='d-flex flex-column'>
                  <TYPE.main mr={'1rem'}>
                    Pairs: <Medium>{localNumber(pairCount)}</Medium>
                  </TYPE.main>
                  <TYPE.main className="mt-1">
                    Fees (24H): <Medium>{oneDayFees}</Medium>&nbsp;
                  </TYPE.main>
                </div>
              </div>
            )
          }
        </RowFixed>
      </RowBetween>
    </Header>
  )
}
