import React, { useEffect, useState } from 'react'
import 'feather-icons'
import { withRouter } from 'react-router-dom'
import { TYPE } from '../Theme'
import { PageWrapper, FullWrapper } from '../components'
import Panel from '../components/Panel'
import LPList from '../components/LPList'
import styled from 'styled-components'
import { useTopLps } from '../contexts/GlobalData'
// import LocalLoader from '../components/LocalLoader'
import { RowBetween } from '../components/Row'
import { useMedia } from 'react-use'
import Search from '../components/Search'
// import Web3 from "web3";
// import { SupportedNetwork } from '../constants/networks'
import LiquiditySearch from '../components/LiquiditySearch'
// import { useNetworkSelection } from '../contexts/LocalStorage'
// import lockTokenABA from "../constants/abis/lockTokenABA.json"
// import lockFactoryABI from "../constants/abis/lockFactoryBalanceABI.json"

const AccountWrapper = styled.div`
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

function LockLiquidity() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // const topLps = useTopLps()
  // const [network] = useNetworkSelection()

  const below600 = useMedia('(max-width: 600px)')
  
  // let web3 = new Web3(
  //   new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/")
  // );

  // switch (network) {
  //   case SupportedNetwork.ARBITRUM:
  //     web3 = new Web3(
  //       new Web3.providers.HttpProvider("https://falling-spring-spree.arbitrum-mainnet.quiknode.pro/a905ec64e4a919463ed20b8dbcc98fe4320c2194/")
  //     );
  //     break;
  //   case SupportedNetwork.ETHEREUM:
  //     web3 = new Web3(
  //       new Web3.providers.HttpProvider("https://young-distinguished-wish.quiknode.pro/5847a774b4342976e36424ec208e9881499ab5eb/")
  //     );
  //     break;
  //   case SupportedNetwork.OPTIMISM:
  //     web3 = new Web3(
  //       new Web3.providers.HttpProvider("https://still-restless-brook.optimism.quiknode.pro/852cfd239fdf09a123151455f5a0b1713b9af12b/")
  //     );
  //     break;
  //   case SupportedNetwork.POLYGON:
  //     web3 = new Web3(
  //       new Web3.providers.HttpProvider("https://dry-alien-aura.matic.quiknode.pro/912ad5bf5047ab28554965b3ae46ce0d4f976cca/")
  //     );
  //     break;
  //   default:
  //     web3 = new Web3(
  //       new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/")
  //     );
  // }

  // const [lockLPs, setLockLps] = useState([]);
  
  // const listOfPoolsForLock = ["SWAP", "EXTICO"];

  // useEffect(() => {
  //   if(topLps) {

  //     topLps.map((item) => {
  //       const a = listOfPoolsForLock.map(async x => {
      
  //         const contractAddress = x == listOfPoolsForLock[0] ? process.env['REACT_APP_LOCK_FACTORY_ADDRESS'] : process.env['REACT_APP_LOCK_FACTORY_ADDRESS_EXTICO'];
    
  //         if (contractAddress != "0x0000000000000000000000000000000000000000") {
  //           const contract = new web3.eth.Contract(lockFactoryABI, contractAddress);
  //           const response = await contract.methods.getLockPair(item.user.id, item.pairAddress).call();

  //           if(response !== "0x0000000000000000000000000000000000000000") {

  //             const contract = new web3.eth.Contract(lockTokenABA, response);
  //             const data = await contract.methods.getUserNumLocksForToken(item.user.id, item.pairAddress).call()

  //             const balanceOfABI = [
  //               {
  //                 constant: true,
  //                 inputs: [
  //                   {
  //                     name: "_owner",
  //                     type: "address"
  //                   }
  //                 ],
  //                 name: "balanceOf",
  //                 outputs: [
  //                   {
  //                     name: "balance",
  //                     type: "uint256"
  //                   }
  //                 ],
  //                 payable: false,
  //                 stateMutability: "view",
  //                 type: "function"
  //               },
  //               {
  //                 constant : true,
  //                 inputs: [],
  //                 name: "totalSupply",
  //                 outputs: [
  //                   {
  //                     name: "",
  //                     type: "uint256"
  //                   }
  //                 ],
  //                 payable: false,
  //                 stateMutability: "view",
  //                 type: "function"
  //               },
  //             ];

  //             const contractBalance = new web3.eth.Contract(balanceOfABI, item.pairAddress);
  //             const balance = await contractBalance.methods.balanceOf(response).call();

  //             const convertedBalance = (parseFloat((Number(balance) / (10**18)))/parseFloat(item.totalSupply))*parseFloat(item.usdValue)

              
  //             if(!lockLPs.includes(item)) {
  //               item.totalLocks = data
  //               item.balance = convertedBalance
  //               setLockLps(prev => [...prev, item])
                
  //             }
  //           }
  //         }
  //       })
  //     })
  //   }

  // },[topLps])


  return (
    <PageWrapper>
      <FullWrapper>
        <RowBetween>
          <TYPE.largeHeader>Lock Wallet analytics</TYPE.largeHeader>
          {!below600 && <Search small={true} />}
        </RowBetween>
        <AccountWrapper>
          <LiquiditySearch />
        </AccountWrapper>
        {/* <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '2rem' }}>
          Top Lock Liquidity Positions
        </TYPE.main>
        <Panel>{lockLPs && lockLPs.length > 0 ? <LPList lps={lockLPs} maxItems={20} liquidity={true} /> : <LocalLoader />}</Panel> */}
      </FullWrapper>
    </PageWrapper>
  )
}

export default withRouter(LockLiquidity)
