import React from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { TYPE } from '../../Theme'
import { useSessionStart } from '../../contexts/Application'
import Toggle from '../Toggle'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import { RowBetween } from '../Row'

const Polling = styled.div`
  display: flex;
  padding: 1rem;
  color: white;
  opacity: 0.4;
  transition: opacity 0.25s ease;
  :hover {
    opacity: 1;
  }
  z-index: 9998;
`
const PollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.green1};
`

export default function MobileFooter() {
  const below1080 = useMedia('(max-width: 1080px)')
  const below600 = useMedia('(max-width: 600px)')

  const seconds = useSessionStart()

  const [isDark, toggleDarkMode] = useDarkModeManager()

  return (
    <>
    <RowBetween>
      <Polling>
        <PollingDot />
        <a href="/">
          <TYPE.small>
            Updated {!!seconds ? seconds + 's' : '-'} ago <br />
          </TYPE.small>
        </a>
      </Polling>
      {
        below1080 && !below600 && (
          <TYPE.small style={{margin: '0.5rem 1rem'}}>App Version: 1.0.1</TYPE.small>
        )
      }
      <div style={{margin: "0 0.5rem 0.5rem"}}>
        <Toggle isActive={isDark} toggle={toggleDarkMode}/>
      </div>
    </RowBetween>
    </>
  )
}
