import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from 'rebass'
import { TYPE } from '../../Theme'
import { RowFixed } from '../Row'
import Wordmark from '../../assets/Swap_Analytics_Logo.svg'

import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
import { AutoColumn } from '../Column'
import { TrendingUp, List, PieChart, Disc, Lock, Menu, X } from 'react-feather'
import NetworkDropdown from '../../components/NetworkDropdown'
import { SUPPORTED_NETWORK_VERSIONS } from '../../constants/networks'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 10;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`
const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.text1};
  display: flex;
  margin-left: 12px;
  :hover {
    opacity: 1;
  }
`
const MenuLink = styled(BasicLink)`
  border-bottom: 1px solid ${({ theme }) => theme.text4};
  padding-bottom: 1rem
`

const NetworkWrapper = styled.div`
  // background-color: ${({ theme }) => theme.topColor};
  padding: 0.7rem 1.4rem;
  display: flex;
  justify-content: start
`

const Hamburger = styled.div`
  height: 50px;
  width: 50px;
  background: ${({ theme }) => theme.bg7};
  color: ${({ theme }) => theme.text1};
  border-radius: 10px;
  cursor: pointer;
  // box-shadow: 0 0 4px rgba(0,0,0,0);
  transition: all .2s ease;
  z-index: 9999;
  box-shadow: 0 0 8px rgba(0,0,0,0.15);
`
const Nav = styled.div`
  left: 0;
  background:  ${({ theme }) => theme.bg7};
  width: 100%;
  box-shadow: 0 1px 20px rgba(0,0,0,0.25);
  position: fixed;
  height: 100%;
  padding-top: 1rem;
  top: 0;

  ul {
    list-style: none;
    padding: 0;
    
    li {
      width: 100%;
      transition: all .15s ease;
      
      a {
        display: block;
        padding: 20px;
        width: 100%;
        height: 100%;
      }
      
      &:hover {
        background: @purple;
        color: @white;
        transition: all .15s ease;
      }
    }
  }
`

export default function Title({network, handleChange}) {
  const history = useHistory()
  const below1080 = useMedia('(max-width: 1080px)')
  const below600 = useMedia('(max-width: 600px)')
  const below800 = useMedia('(max-width: 800px)')

  const [ toggle, setToggle ] = useState(true)

  return (
    <TitleWrapper>
      <Flex alignItems="center" style={{ justifyContent: 'space-between' }}>
        <RowFixed>
         
          {!below1080 && (
            <div onClick={() => history.push('/')}>
              <img width={'170px'} src={Wordmark} alt="logo" />
            </div>
          )}
          {
            below1080 && (
              <div onClick={() => history.push('/')}>
                <img width={'50px'} src="https://raw.githubusercontent.com/panaromafinance/tokens-list/main/assets/0x4e5E55bAeEf3bc747D22123cE4ADE3661c916a3e/logo.png" alt="logo"/>
              </div>
            )
          }
        </RowFixed>
        {below1080 && !below600 && (
          <RowFixed style={{ alignItems: 'center' }}>
            <BasicLink to="/home">
              <Option activeText={history.location.pathname === '/home' ?? undefined}>Overview</Option>
            </BasicLink>
            <BasicLink to="/tokens">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'tokens' ||
                    history.location.pathname.split('/')[1] === 'token') ??
                  undefined
                }
              >
                Tokens
              </Option>
            </BasicLink>
            <BasicLink to="/pairs">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'pairs' ||
                    history.location.pathname.split('/')[1] === 'pair') ??
                  undefined
                }
              >
                Pairs
              </Option>
            </BasicLink>

            <BasicLink to="/accounts">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'accounts' ||
                    history.location.pathname.split('/')[1] === 'account') ??
                  undefined
                }
              >
                Accounts
              </Option>
            </BasicLink>

            <BasicLink to="/liquidity">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'liquidity' ||
                    history.location.pathname.split('/')[1] === 'liquidity') ??
                  undefined
                }
              >
                Lock Liquidity
              </Option>
            </BasicLink>
            <NetworkWrapper>
              <NetworkDropdown options={SUPPORTED_NETWORK_VERSIONS} active={network} setActive={handleChange} color={'#0066ff'} below800={below800}/>
            </NetworkWrapper>
          </RowFixed>
        )}
        {
          below600 && (
            <RowFixed>
              <Hamburger id="hamburger" className="d-flex align-items-center justify-content-center" onClick={() => setToggle(!toggle)}>
                {/* <span></span>
                <span></span>
                <span></span>   */} 
                {
                  toggle ? <Menu/> : <X/>
                }
              </Hamburger>
              <Nav id="nav" className={toggle ? "d-none" : "d-block"}>
                <NetworkWrapper>
                  <NetworkDropdown options={SUPPORTED_NETWORK_VERSIONS} active={network} setActive={handleChange} color={'#0066ff'} below800={below800}/>
                </NetworkWrapper>
                <AutoColumn gap="1.25rem" style={{ marginTop: '1rem' }}>
                  <MenuLink to="/home" onClick={() => setToggle(!toggle)}>
                    <Option activeText={history.location.pathname === '/home' ?? undefined} className='pt-1'>
                      <TrendingUp size={20} style={{ marginRight: '.75rem' }} />
                      Overview
                    </Option>
                  </MenuLink>
                  <MenuLink to="/tokens" onClick={() => setToggle(!toggle)}>
                    <Option
                      activeText={
                        (history.location.pathname.split('/')[1] === 'tokens' ||
                          history.location.pathname.split('/')[1] === 'token') ??
                        undefined
                      }
                    >
                      <Disc size={20} style={{ marginRight: '.75rem' }} />
                      Tokens
                    </Option>
                  </MenuLink>
                  <MenuLink to="/pairs" onClick={() => setToggle(!toggle)}>
                    <Option
                      activeText={
                        (history.location.pathname.split('/')[1] === 'pairs' ||
                          history.location.pathname.split('/')[1] === 'pair') ??
                        undefined
                      }
                    >
                      <PieChart size={20} style={{ marginRight: '.75rem' }} />
                      Pairs
                    </Option>
                  </MenuLink>
                  <MenuLink to="/accounts" onClick={() => setToggle(!toggle)}>
                    <Option
                      activeText={
                        (history.location.pathname.split('/')[1] === 'accounts' ||
                          history.location.pathname.split('/')[1] === 'account') ??
                        undefined
                      }
                    >
                      <List size={20} style={{ marginRight: '.75rem' }} />
                      Accounts
                    </Option>
                  </MenuLink>
                  <MenuLink to="/liquidity" onClick={() => setToggle(!toggle)}>
                    <Option
                      activeText={
                        (history.location.pathname.split('/')[1] === 'liquidity' ||
                          history.location.pathname.split('/')[1] === 'liquidity') ??
                        undefined
                      }
                    >
                      <Lock size={20} style={{ marginRight: '.75rem' }} />
                      Lock Liquidity
                    </Option>
                  </MenuLink>
                  <TYPE.small style={{margin: '0.5rem 1rem'}}>App Version: 1.0.1</TYPE.small>
                </AutoColumn>
              </Nav> 
            </RowFixed>
          )
        }
      </Flex>
    </TitleWrapper>
  )
}
