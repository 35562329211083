import React, { useState, useEffect } from 'react'
import { useMedia } from 'react-use'
import dayjs from 'dayjs'
import LocalLoader from '../LocalLoader'
import utc from 'dayjs/plugin/utc'
import { Box, Flex, Text } from 'rebass'
import styled from 'styled-components'
import Link, { CustomLink } from '../Link'
import { Divider } from '../../components'
import DoubleTokenLogo from '../DoubleLogo'
import { withRouter } from 'react-router-dom'
import { formattedNum, getPoolLink } from '../../utils'
import { AutoColumn } from '../Column'
import { useEthPrice } from '../../contexts/GlobalData'
import { RowFixed } from '../Row'
import { ButtonLight } from '../ButtonStyled'
import { TYPE } from '../../Theme'
import FormattedName from '../FormattedName'
import Panel from '../../components/Panel'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.primary1};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns:  ${({ liquidity }) => liquidity ? '5px 1.5fr 0.5fr 1fr 1fr' : '5px 0.5fr 1fr 1fr'};
  grid-template-areas:  ${({ liquidity }) => liquidity ? 'number name lock panaswap return' : 'number name panaswap return'};
  align-items: flex-start;
  padding: 20px 0;

  > * {
    justify-content: flex-end;
    width: 100%;

    :first-child {
      justify-content: flex-start;
      text-align: left;
      width: 20px;
    }
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns:  ${({ liquidity }) => liquidity ? '35px 2.5fr 1fr 1fr 1fr' : '35px 2.5fr 1fr 1fr'};
    grid-template-areas:  ${({ liquidity }) => liquidity ? 'number name lock panaswap return' : 'number name panaswap return'};
  }

  @media screen and (max-width: 740px) {
    grid-template-columns: 2.5fr 1fr 1fr;
    grid-template-areas: 'name panaswap return';
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 2.5fr 1fr;
    grid-template-areas: 'name panaswap';
  }
`

const ListWrapper = styled.div``

const ClickableText = styled(Text)`
  color: ${({ theme }) => theme.text1};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  text-align: end;
  user-select: none;
`

const DataText = styled(Flex)`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.text1};
  & > * {
    font-size: 1em;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`

const SORT_FIELD = {
  VALUE: 'VALUE',
  PANASWAP_RETURN: 'PANASWAP_RETURN',
}

function PositionList({ positions , liquidity = false, lock = null }) {
  const below500 = useMedia('(max-width: 500px)')
  const below740 = useMedia('(max-width: 740px)')

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const ITEMS_PER_PAGE = 10

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.VALUE)

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [positions])

  useEffect(() => {
    if (positions) {
      let extraPages = 1
      if (positions.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(positions.length / ITEMS_PER_PAGE) + extraPages || 1)
    }
  }, [positions])

  const [items, setItems] = useState([])

  const [ethPrice] = useEthPrice()

  const ListItem = ({ position, index }) => {
    const poolOwnership = position.liquidityTokenBalance / position.pair.totalSupply
    const valueUSD = poolOwnership * position.pair.reserveUSD
    let active = 0;
    let inactive = 0;

    if(lock && liquidity) {
      lock.map(item => {
        if(position.pair.id === item.pair) {
          item.data.map(i => {
            if(new Date(i.to * 1000) <= new Date()) {
              active++;
            } else {
              inactive++;
            }
          })
        }
      })
    }

    return (
      <>
        <DashGrid style={{ opacity: poolOwnership > 0 ? 1 : 0.6 }} focus={true} liquidity={liquidity}>
          {!below740 && <DataText area="number">{index}</DataText>}
          <DataText area="name" justifyContent="flex-start" alignItems="flex-start">
            <AutoColumn gap="8px" justify="flex-start" align="flex-start">
              <DoubleTokenLogo size={16} a0={position.pair.token0.id} a1={position.pair.token1.id} margin={!below740} />
            </AutoColumn>
            <AutoColumn gap="8px" justify="flex-start" style={{ marginLeft: '20px' }}>
              <CustomLink to={'/pair/' + position.pair.id}>
                <TYPE.main style={{ whiteSpace: 'nowrap' }} to={'/pair/'}>
                  <FormattedName
                    text={position.pair.token0.symbol + '-' + position.pair.token1.symbol}
                    maxCharacters={below740 ? 10 : 18}
                  />
                </TYPE.main>
              </CustomLink>

              <RowFixed gap="8px" justify="flex-start">
                <Link
                  external
                  href={getPoolLink(position.pair.token0.id, position.pair.token1.id)}
                  style={{ marginRight: '.5rem' }}
                >
                  <ButtonLight style={{ padding: '4px 6px', borderRadius: '4px' }}>Add</ButtonLight>
                </Link>
                {poolOwnership > 0 && (
                  <Link external href={getPoolLink(position.pair.token0.id, position.pair.token1.id, true)}>
                    <ButtonLight style={{ padding: '4px 6px', borderRadius: '4px' }}>Remove</ButtonLight>
                  </Link>
                )}
                {
                  liquidity && 
                  <ButtonLight style={{ padding: '4px 6px', borderRadius: '4px', marginLeft: '.5rem' }} onClick={() => {
                    let showItems = items.slice()
                    if(showItems.includes(position.pair.id)){
                      const indexArr = showItems.indexOf(position.pair.id)
                      showItems.splice(indexArr,1)
                    } else {
                      showItems.push(position.pair.id)
                    }
                    setItems(showItems)
                  }}>Locks ({
                    lock && lock.map(i => {
                      if(i.pair === position.pair.id) {
                        return i.data.length
                      }
                    })
                  })</ButtonLight>
                }
              </RowFixed>
            </AutoColumn>
          </DataText>
          {
            !below740 && liquidity &&(
              <DataText area="lock" justifyContent="flex-start" alignItems="flex-start">
                <AutoColumn gap="8px">
                  <RowFixed>
                    Active/Inactive
                  </RowFixed>
                  <RowFixed justifyContent="space-around" className="w-100">
                    <span className='text-success'>{active}</span>
                    <span className='text-danger'>{inactive}</span>
                  </RowFixed>
                </AutoColumn>
              </DataText>
            )
          }
          <DataText area="panaswap">
            <AutoColumn gap="12px" justify="flex-end">
              <TYPE.main>{formattedNum(valueUSD, true, true)}</TYPE.main>
              <AutoColumn gap="4px" justify="flex-end">
                <RowFixed>
                  <TYPE.small fontWeight={400}>
                    {formattedNum(poolOwnership * parseFloat(position.pair.reserve0))}{' '}
                  </TYPE.small>
                  <FormattedName
                    text={position.pair.token0.symbol}
                    maxCharacters={below740 ? 10 : 18}
                    margin={true}
                    fontSize={'11px'}
                  />
                </RowFixed>
                <RowFixed>
                  <TYPE.small fontWeight={400}>
                    {formattedNum(poolOwnership * parseFloat(position.pair.reserve1))}{' '}
                  </TYPE.small>
                  <FormattedName
                    text={position.pair.token1.symbol}
                    maxCharacters={below740 ? 10 : 18}
                    margin={true}
                    fontSize={'11px'}
                  />
                </RowFixed>
              </AutoColumn>
            </AutoColumn>
          </DataText>
          {!below500 && (
            <DataText area="return">
              <AutoColumn gap="12px" justify="flex-end">
                <TYPE.main color={'green'}>
                  <RowFixed>{formattedNum(position?.fees.sum, true, true)}</RowFixed>
                </TYPE.main>
                <AutoColumn gap="4px" justify="flex-end">
                  <RowFixed>
                    <TYPE.small fontWeight={400}>
                      {parseFloat(position.pair.token0.derivedETH)
                        ? formattedNum(
                            position?.fees.sum / (parseFloat(position.pair.token0.derivedETH) * ethPrice) / 2,
                            false,
                            true
                          )
                        : 0}{' '}
                    </TYPE.small>
                    <FormattedName
                      text={position.pair.token0.symbol}
                      maxCharacters={below740 ? 10 : 18}
                      margin={true}
                      fontSize={'11px'}
                    />
                  </RowFixed>
                  <RowFixed>
                    <TYPE.small fontWeight={400}>
                      {parseFloat(position.pair.token1.derivedETH)
                        ? formattedNum(
                            position?.fees.sum / (parseFloat(position.pair.token1.derivedETH) * ethPrice) / 2,
                            false,
                            true
                          )
                        : 0}{' '}
                    </TYPE.small>
                    <FormattedName
                      text={position.pair.token1.symbol}
                      maxCharacters={below740 ? 10 : 18}
                      margin={true}
                      fontSize={'11px'}
                    />
                  </RowFixed>
                </AutoColumn>
              </AutoColumn>
            </DataText>
          )}
        </DashGrid>
        {
          liquidity && items.includes(position.pair.id)? (
            lock.map((i,index) => {
              return(
                <div key={index}>
                  {
                    position.pair.id === i.pair && (
                      i.data && i.data.map((data,k) => {
                        return (
                          <Panel key={k} marginBottom={"10px"}>
                            <RowFixed>
                              <TYPE.body>
                                <span style={{fontWeight: "500"}}>Total Locked: </span>
                                <span>{data.amountHolding0 ? formattedNum(((Number(data.amountHolding0) /10**18) / position.pair.totalSupply) * i.reserveUSD, true) : 0}</span> 
                              </TYPE.body>
                            </RowFixed>
                            <RowFixed>
                              <TYPE.body>
                                <span style={{fontWeight: "500"}}>Lock Created: </span>
                                <span>{new Date(data.timeStamp * 1000).toLocaleString([], {
                                      timeZone: "utc",
                                      hour12: false,
                                  })}</span> 
                              </TYPE.body>
                            </RowFixed>
                            <RowFixed>
                              <TYPE.body>
                                <span style={{fontWeight: "500"}}>Lock Released: </span>
                                <span>{new Date(data.to * 1000).toLocaleString([], {
                                    timeZone: "utc",
                                    hour12: false,
                                })}
                                </span>
                              </TYPE.body>
                              
                            </RowFixed>
                          </Panel>
                        )
                      })
                    )
                  }
                </div>
              )
            })
          ) : null
        }
      </>
    )
  }


  const positionsSorted = liquidity && positions ? (
    positions&&
    positions

      .sort((p0, p1) => {
        if (sortedColumn === SORT_FIELD.PRINCIPAL) {
          return p0[0]?.principal?.usd > p1[0]?.principal?.usd ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        if (sortedColumn === SORT_FIELD.HODL) {
          return p0[0]?.hodl?.sum > p1[0]?.hodl?.sum ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        if (sortedColumn === SORT_FIELD.PANASWAP_RETURN) {
          return p0[0]?.panaswap?.return > p1[0]?.panaswap?.return ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        if (sortedColumn === SORT_FIELD.VALUE) {
          const bal0 = (p0[0].liquidityTokenBalance / p0[0].pair.totalSupply) * p0[0].pair.reserveUSD
          const bal1 = (p1[0].liquidityTokenBalance / p1[0].pair.totalSupply) * p1[0].pair.reserveUSD
          return bal0 > bal1 ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        return 1
      })
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
      .map((position, index) => {
        return (
          <div key={index}>
            <ListItem key={index} index={(page - 1) * 10 + index + 1} position={position[0]} />
            <Divider />
          </div>
        )
      })
  ) : (
    positions &&
    positions

      .sort((p0, p1) => {
        if (sortedColumn === SORT_FIELD.PRINCIPAL) {
          return p0?.principal?.usd > p1?.principal?.usd ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        if (sortedColumn === SORT_FIELD.HODL) {
          return p0?.hodl?.sum > p1?.hodl?.sum ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        if (sortedColumn === SORT_FIELD.PANASWAP_RETURN) {
          return p0?.panaswap?.return > p1?.panaswap?.return ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        if (sortedColumn === SORT_FIELD.VALUE) {
          const bal0 = (p0.liquidityTokenBalance / p0.pair.totalSupply) * p0.pair.reserveUSD
          const bal1 = (p1.liquidityTokenBalance / p1.pair.totalSupply) * p1.pair.reserveUSD
          return bal0 > bal1 ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        return 1
      })
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
      .map((position, index) => {
        return (
          <div key={index}>
            <ListItem key={index} index={(page - 1) * 10 + index + 1} position={position} />
            <Divider />
          </div>
        )
      })
  )

  return (
    <ListWrapper>
      <DashGrid center={true} style={{ height: '32px', padding: 0 }} liquidity={liquidity}>
        {!below740 && (
          <Flex alignItems="flex-start" justifyContent="flexStart">
            <TYPE.main area="number">#</TYPE.main>
          </Flex>
        )}
        <Flex alignItems="flex-start" justifyContent="flex-start">
          <TYPE.main area="name">Name</TYPE.main>
        </Flex>
        {
          !below740 && liquidity &&(
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <TYPE.main area="lock">Lock Status</TYPE.main>
            </Flex>
          )
        }
        <Flex alignItems="center" justifyContent="flexEnd">
          <ClickableText
            area="panaswap"
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.VALUE)
              setSortDirection(sortedColumn !== SORT_FIELD.VALUE ? true : !sortDirection)
            }}
          >
            {below740 ? 'Value' : 'Liquidity'} {sortedColumn === SORT_FIELD.VALUE ? (!sortDirection ? '↑' : '↓') : ''}
          </ClickableText>
        </Flex>
        {!below500 && (
          <Flex alignItems="center" justifyContent="flexEnd">
            <ClickableText
              area="return"
              onClick={() => {
                setSortedColumn(SORT_FIELD.PANASWAP_RETURN)
                setSortDirection(sortedColumn !== SORT_FIELD.PANASWAP_RETURN ? true : !sortDirection)
              }}
            >
              {below740 ? 'Fees' : 'Total Fees Earned'}{' '}
              {sortedColumn === SORT_FIELD.PANASWAP_RETURN ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        )}
      </DashGrid>
      <Divider />
      <List p={0}>{!positionsSorted ? <LocalLoader /> : positionsSorted}</List>
      <PageButtons>
        <div onClick={() => setPage(page === 1 ? page : page - 1)}>
          <Arrow faded={page === 1 ? true : false}>←</Arrow>
        </div>
        <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
        <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
          <Arrow faded={page === maxPage ? true : false}>→</Arrow>
        </div>
      </PageButtons>
    </ListWrapper>
  )
}

export default withRouter(PositionList)
