export const FACTORY_ADDRESS = '0x150170a4A4fA8A34dfC407f68CDE789E4dFfC103'

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  'https://gateway.ipfs.io/ipns/tokens.panaroma.finance'
  // 'https://www.coingecko.com/tokens_list/panaswap/defi_100/v_0_0_0.json',
]

// hide from overview list
export const TOKEN_BLACKLIST = [
  
]

// pair blacklist
export const PAIR_BLACKLIST = [
  '0xb6a741f37d6e455ebcc9f17e2c16d0586c3f57a5',
  '0x97cb8cbe91227ba87fc21aaf52c4212d245da3f8',
  '0x1acba73121d5f63d8ea40bdc64edb594bd88ed09',
  '0x7d7e813082ef6c143277c71786e5be626ec77b20',
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76':
    'TikTok Inc. has asserted this token is violating its trademarks and therefore is not available.',
}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = ['0xd46ba6d942050d489dbd938a2c909a5d5039a161']

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or ETH pairings.'

// pairs that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES_PAIRS = [
  '0x9928e4046d7c6513326ccea028cd3e7a91c7590a',
  '0x87da823b6fc8eb8575a235a824690fda94674c88',
  '0xcd7989894bc033581532d2cd88da5db0a4b12859',
  '0xe1573b9d29e2183b1af0e743dc2754979a40d237',
  '0x45804880de22913dafe09f4980848ece6ecbaf78',
  '0x709f7b10f22eb62b05913b59b92ddd372d4e2152',
  '0xe0e7db62d8aebd1838ff768e1e1a448c950d616d',
  '0x75bd6bf71f020f17edc83adf505d5ceed0d96e10',
  '0x9b74205381193cc42429da19eb8deea11a7b3f80',
  '0x81a9c78e64c290fae05e9a36deaf69dc70a3b68f',
  '0x829c18260fb3d246ab7592eaba1c04f0dd765bde',
  '0x25bca3648a15fcdcb53651ff0cfec46f98001acf',
  '0x51ddf4d70da636b7e2d6197eda8d99c1b89c5656',
  '0x58f49995ec802fd519a9699b23f9257ccc11b9aa',
  '0xfbdb795258c86fb0670e681f201d18a94c0827a5',
  '0x01bea27e17c561aac279020f8c602950f5b4ba15'
]

// tokens that should be tracked but arent due to lag in subgraph
// all pairs that include token will be tracked
export const TRACKED_OVERRIDES_TOKENS = ['0x956f47f50a910163d8bf957cf5846d573e7f87ca', '0x166d24cc525d47bb77fab58eb715ab765e1e62a9']
