import React, { useState } from 'react'
import styled from 'styled-components'

import Row from '../Row'
import { AutoColumn } from '../Column'
import { ChevronDown as Arrow, Pocket } from 'react-feather'
import { StyledInternalLink, TYPE } from '../../Theme'
import { StyledIcon } from '..'
import { EthereumNetworkInfo, PolygonNetworkInfo } from '../../constants/networks'
import { CustomLink } from '../Link'

const Wrapper = styled.div`
  width: fit-content;
  z-index: 20;
  position: relative;
  background-color: ${({ theme }) => theme.panelColor};
  border: 1px solid ${({ open, color }) => (open ? color : 'rgba(0, 0, 0, 0.15);')} 
  padding: 4px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: ${({ mobile }) => mobile ? "start" : "end"} 
  // width: ${({ mobile }) => mobile ? "40%" : ""} 

  :hover {
    cursor: pointer;
  }
`
const Network = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 38px;
  padding-top: 40px;
//   width: calc(100% - 40px);
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 10px;
  border-radius: 8px;
//   width: calc(100% - 20px);
  font-weight: 500;
  font-size: 1rem;
  color: black;
  left: 0;
  width: 100%;
  :hover {
    cursor: pointer;
  }
`

const StyledRow = styled(Row)`
  background-color: ${({ theme, selected }) => selected ? theme.bg3 : "none"};
  border-radius: ${({ selected }) => selected ? "8px" : ""};
  padding: ${({ selected }) => selected ? "0.5rem" : "0.1rem"};
`

const ArrowStyled = styled(Arrow)`
  height: 20px;
  width: 20px;
  margin-left: 6px;
  margin-top: 4px;
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 6px;
`;

const FlyoutRowActiveIndicator = styled.div`
  background-color: green;
  border-radius: 50%;
  height: 9px;
  width: 9px;
`;

const CircleContainer = styled.div`
  width: 20px;
  display: flex;
  justify-content: end;
`;

const NetworkDropdown = ({ options, active, setActive, color, below800 }) => {
    const [showDropdown, toggleDropdown] = useState(false)

    return (
        // <div onMouseEnter={() => toggleDropdown(true)}>
            <Wrapper open={showDropdown} color={color} mobile={below800}>
                <Network onClick={() => toggleDropdown(!showDropdown)}>
                    {
                        Object.keys(options).map((key, index) => {
                            let option = options[key]
                            return (
                                option.name === active && (
                                    <StyledLogo src={option.imageURL} key={index}/>
                                )
                            )
                        })
                    }
                    <TYPE.main>{active}</TYPE.main>
                    <StyledIcon>
                        <ArrowStyled />
                    </StyledIcon>
                </Network>
                {showDropdown && (
                    <Dropdown>
                        <AutoColumn gap="20px">
                            <TYPE.light>Select a Network</TYPE.light>
                            {Object.keys(options).map((key, index) => {
                                let option = options[key]
                                return (
                                  // <CustomLink key={index} to={option === EthereumNetworkInfo ? '/' : '/'+ option.route + window.location.pathname}>
                                      <StyledRow selected={option.name === active}
                                          onClick={() => {
                                              toggleDropdown(!showDropdown)
                                              setActive(option.name)
                                          }}
                                          key={index}
                                      >
                                          <StyledLogo src={option.imageURL} />
                                          <TYPE.body fontSize={14} >{option.name}</TYPE.body>
                                          {option.name === active && (
                                              <CircleContainer>
                                                  <FlyoutRowActiveIndicator />
                                              </CircleContainer>
                                          )}
                                      </StyledRow>
                                  // {/* </CustomLink> */}
                                )
                            })}
                        </AutoColumn>
                    </Dropdown>
                )}
            </Wrapper>
        // </div>
    )
}

export default NetworkDropdown
