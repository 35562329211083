import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useDarkModeManager } from '../../contexts/LocalStorage'

const pulse = keyframes`
  0%   {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`
const pulse1 = keyframes`
  100% { transform: scale(1); }
`

const Wrapper = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.fill && !props.height
      ? css`
          height: 100vh;
        `
      : css`
          height: 180px;
        `}
`

const AnimatedImg = styled.div`
  animation: ${pulse1} 800ms linear infinite;
  & > * {
    width: 72px;
  }
`

const Preloader = styled.div`
  position: fixed;
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%;
`

const Loader = styled.div`
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3772ff;
  animation: ${pulse} 2s linear infinite;

  :before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3772ff;
    animation: ${pulse} 3s linear infinite;
  }

  :after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3772ff;
    animation: ${pulse} 1.5s linear infinite;
  }
`


const LocalLoader = ({ fill }) => {
  // const [darkMode] = useDarkModeManager()

  return (
    <Wrapper fill={fill}>
      <AnimatedImg>
        {/* <img src={require(darkMode ? '../../assets/swap_logo.svg' : '../../assets/swap_logo.svg')} alt="loading-icon" /> */}
        <img  src="https://raw.githubusercontent.com/panaromafinance/tokens-list/main/assets/0x4e5E55bAeEf3bc747D22123cE4ADE3661c916a3e/logo.png" alt="logo"/>
        <Preloader id="preloader">
          <Loader >
          
          </Loader>
        </Preloader>
      </AnimatedImg>
    </Wrapper>
  )
}

export default LocalLoader
